.jh-sorter {
	white-space: nowrap;
	outline: none;
}

.jh-sorter-label {
	white-space: normal;
}

.jh-sorter > .jh-sorter-icon {
	opacity: 0.5;
}

.jh-sorter:hover > .jh-sorter-icon {
	opacity: 1;
}
