body {
	padding-top: 100px;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
	display: none !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */

.navbar-brand .logo-img {
	vertical-align: middle;
	display: inline-block;
}

/* ==========================================================================
Generic styles
========================================================================== */

.stack {
	display: inline-block;
	text-align: center;
}

.column {
	display: inline-block;
	vertical-align: middle;
}

.pad {
	padding: 10px;
}

.break {
	white-space: normal;
	word-break: break-all;
}

.voffset {
	margin-top: 2px;
}
.voffset1 {
	margin-top: 5px;
}
.voffset2 {
	margin-top: 10px;
}
.voffset3 {
	margin-top: 15px;
}
.voffset4 {
	margin-top: 30px;
}
.voffset5 {
	margin-top: 40px;
}
.voffset6 {
	margin-top: 60px;
}
.voffset7 {
	margin-top: 80px;
}
.voffset8 {
	margin-top: 100px;
}
.voffset9 {
	margin-top: 150px;
}

.readonly {
	background-color: #eee;
	opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover,
.hand {
	cursor: pointer;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */

ul#strengthBar {
	display: inline;
	list-style: none;
	margin: 0;
	margin-left: 15px;
	padding: 0;
	vertical-align: 2px;
}

.point {
	background: #ddd;
	border-radius: 2px;
	display: inline-block;
	height: 5px;
	margin-right: 1px;
	width: 20px;
}

.point:last {
	margin: 0 !important;
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */

.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice,
.ui-select-bootstrap:not(.ui-select-multiple) .btn-default,
.ui-select-bootstrap:not(.ui-select-multiple)
	.btn-default:active:hover
	.ui-select-bootstrap:not(.ui-select-multiple)
	.btn-default:active:focus {
	color: black;
	background-color: white;
}

.ui-select-multiple.ui-select-bootstrap {
	height: 39px;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
	padding: 5px 5px;
}

/* ==========================================================================
Custom
========================================================================== */

.help-block {
	float: right !important;
	margin: 0;
}

.drop-box {
	border: 5px dashed transparent;
	margin: -5px;
}
.dragover {
	border: 5px dashed gray;
}

.inline-input-number {
	width: 7em !important;
}

.btn-head {
	width: 1em;
}

.pvtTotal,
.pvtTotalLabel,
.pvtGrandTotal {
	display: none;
}

table.pvtTable .pvtColLabel {
	text-align: center;
}

.pvtVal {
	text-align: right;
}

/* Aligns labels with validation messages */
.control-label {
	vertical-align: bottom;
}

.road-segment th {
	text-align: center;
}

.condition-item {
	margin: 0.5em 0;
}
.condition-group {
	border: 1px solid gray; /*rgb(72, 85, 99);*/
}

.table > thead {
	background: rgba(255, 255, 255, 0.05);
}

.table > tbody {
	background: rgba(255, 255, 255, 0.1);
}

.table tr:nth-of-type(odd) {
	background: rgba(255, 255, 255, 0.08);
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td {
	text-align: center;
	vertical-align: middle;
	border: 1px solid #2b3e50;
}

.flexbar {
	display: flex;
	justify-content: space-between;
}

.error {
	color: red;
}

td .form-group {
	margin-bottom: 0;
}

#measurement-map {
	width: 100%;
	height: 450px;
}

.page-measurement-map {
	margin: 10px 0px;
}

.page-measurement-map-loading {
	text-align: center;
	font-size: 16px;
}
