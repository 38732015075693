/*
==========================================================================
Custom alerts for notification
==========================================================================
*/

.alerts .alert {
	text-overflow: ellipsis;
}
.alert pre {
	white-space: pre-wrap;
	background: none;
	border: none;
	font: inherit;
	color: inherit;
	padding: 0;
	margin: 0;
}

.alerts {
	position: fixed;
	width: 100%;
	z-index: 1200;
}

.alerts.left {
	left: 5px;
}

.alerts.right {
	right: 5px;
}

.alerts.top {
	top: 55px;
}

.alerts.bottom {
	bottom: 55px;
}

@media screen and (min-width: 480px) {
	.alerts {
		width: 30%;
	}
}

.alert-container {
	overflow: hidden;
}

.alert-container.ng-enter,
.alert-container.ng-leave {
	transition: all linear 0.4s;
}

.alert-container.ng-enter,
.alert-container.ng-leave.ng-leave-active {
	opacity: 0;
	transform: translateX(100%);
	max-height: 0em;
}

.alert-container.ng-leave,
.alert-container.ng-enter.ng-enter-active {
	opacity: 1;
	transform: translateX(0%);
	max-height: 16em;
}

.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
