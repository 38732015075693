.decision-tree li {
	list-style-type: none;
	border-left: 2px solid #777;
	margin-left: 1em;
	margin-top: -20px;
}

.decision-tree ul {
	padding-left: 3.5em;
	margin: 0;
	position: relative;
}

.decision-label {
	padding: 0.25em 1em;
	position: relative;
}

.decision-label::before {
	content: '';
	position: absolute;
	top: 0;
	left: -2px;
	bottom: 40%;
	width: 0.75em;
	border-bottom: 2px solid #777;
	border-left: 2px solid #777;
	border-top: 0;
	border-right: 0;
	border-bottom-left-radius: 0.5em;
}

.decision-tree li:last-child {
	border-left: 2px solid transparent;
}

.decision-col {
	display: inline-block;
	vertical-align: middle;
	margin-top: -15px;
}

.node {
	border-radius: 8px;
	margin-top: 20px;
	background-color: #acacac;
}

.leaf {
	border-radius: 8px;
	margin-top: 20px;
	background-color: #dde4df;
}

.decision-term {
	display: block;
}
