input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

.cloud_current_folder {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.button_warning {
	border: none !important;
	background-color: transparent;
	border-radius: 30px;
}
